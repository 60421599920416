var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"create-banner-wrapper"},[(_vm.controller.isLoading)?_c('LoadingOverlay'):_c('div',[_c('h2',{staticClass:"title-create"},[_vm._v("Create Pop Up Banner")]),_c('div',{staticClass:"wrapper-content-banner"},[_c('h3',{staticClass:"title-content-banner"},[_vm._v("Content of Pop Up Banner")]),_c('BannerDropdown',{attrs:{"fieldLabel":"Type","id":"banner-type","name":"bannertype","placeholder":"Select Banner Type","required":true,"options":_vm.typeOptions,"errorMessage":!_vm.$v.form.type.required && _vm.hasChanged.type
            ? 'field is required'
            : undefined},on:{"input":function($event){_vm.hasChanged.type = true}},model:{value:(_vm.form.type),callback:function ($$v) {_vm.$set(_vm.form, "type", $$v)},expression:"form.type"}}),_c('BannerTextInput',{attrs:{"fieldLabel":"Title","withCounter":true,"maxCount":70,"id":"banner-title","name":"bannerTitle","type":"textarea","placeholder":"Type the title","required":true,"errorMessage":!_vm.$v.form.title.required && _vm.hasChanged.title
            ? 'field is required'
            : undefined},on:{"input":function($event){_vm.hasChanged.title = true}},model:{value:(_vm.form.title),callback:function ($$v) {_vm.$set(_vm.form, "title", $$v)},expression:"form.title"}}),_c('BannerDropdown',{attrs:{"width":"max-w-148-a","fieldLabel":"Location","id":"location","name":"Location","placeholder":"Select Location for banner","required":true,"options":_vm.locationOptions,"errorMessage":!_vm.$v.form.location.required && _vm.hasChanged.location
            ? 'field is required'
            : undefined},on:{"input":function($event){_vm.hasChanged.location = true}},model:{value:(_vm.form.location),callback:function ($$v) {_vm.$set(_vm.form, "location", $$v)},expression:"form.location"}}),_c('div',{staticClass:"mb-8"},[_c('ImageUpload',{attrs:{"accept":"image/jpeg,image/jpg,image/png","value":_vm.form.image,"text":"Klik Untuk Upload","fieldLabel":"Image Banner","description":"Max size : 5 Mb, Format : jpg Min Dimension : 321 X 121 px","required":true,"maxFileSize":_vm.MAX_SIZE},on:{"on-input":function (file, index) { return _vm.onFileChange(file); },"on-remove":function (index) { return _vm.onRemoveImage(index); }}})],1),_c('BannerTextInput',{attrs:{"fieldLabel":"URL Deeplink","id":"url-deeplink","name":"urlDeeplink","type":"text","required":true,"placeholder":"Input Deeplink for CTA","fieldDescription":"Enter a valid URL link or deeplink in app","errorMessage":!_vm.$v.form.url.url && _vm.hasChanged.url
            ? 'your URL is not valid. Please enter a valid URL link'
            : undefined},on:{"input":function($event){_vm.hasChanged.url = true}},model:{value:(_vm.form.url),callback:function ($$v) {_vm.$set(_vm.form, "url", $$v)},expression:"form.url"}}),_c('BannerTextInput',{attrs:{"fieldLabel":"Timer","id":"timer","name":"timer","type":"number","required":true,"placeholder":"0","fieldDescription":"How long pop up will show (Max 10s)","fieldSuffix":"Seconds","width":"max-w-50","errorMessage":!_vm.$v.form.timer.between && _vm.hasChanged.timer
            ? 'Timer must between 3 - 10 seconds'
            : undefined},on:{"input":function($event){_vm.hasChanged.timer = true}},model:{value:(_vm.form.timer),callback:function ($$v) {_vm.$set(_vm.form, "timer", $$v)},expression:"form.timer"}}),_c('BannerDropdown',{attrs:{"width":"max-w-148-a","fieldLabel":"Showing Time","id":"showing-time","name":"showingTime","placeholder":"Select how many time pop up will be shown ","required":true,"options":_vm.showTimeOptions,"errorMessage":!_vm.$v.form.showTime.required && _vm.hasChanged.showTime
            ? 'field is required'
            : undefined},on:{"input":function($event){_vm.hasChanged.showTime = true}},model:{value:(_vm.form.showTime),callback:function ($$v) {_vm.$set(_vm.form, "showTime", $$v)},expression:"form.showTime"}})],1),_c('h2',{staticClass:"title-publish-date"},[_vm._v("Publish Date and Time")]),_c('div',{staticClass:"wrapper-publish-date"},[_c('div',{staticClass:"section-date"},[_c('BannerDatePicker',{attrs:{"min-date":_vm.todayDate,"valueFormat":"date","formatted":"DD/MM/YYYY","fieldLabel":"Start Date","id":"banner-start-date","name":"bannerStartDate","label":"DD/MM/YYYY","required":true,"auto-close":true,"errorMessage":_vm.$v.form.startDate.$invalid && _vm.hasChanged.startDate
              ? 'field is required'
              : undefined,"showIconWarning":""},on:{"input":_vm.isStartDateMoreEndDate},model:{value:(_vm.form.startDate),callback:function ($$v) {_vm.$set(_vm.form, "startDate", $$v)},expression:"form.startDate"}}),_c('BannerDatePicker',{attrs:{"min-date":_vm.form.startDate || _vm.todayDate,"valueFormat":"date","formatted":"DD/MM/YYYY","fieldLabel":"End Date","id":"banner-end-date","name":"bannerEndDate","label":"DD/MM/YYYY","required":true,"auto-close":true,"errorMessage":_vm.$v.form.endDate.$invalid && _vm.hasChanged.endDate
              ? 'field is required'
              : undefined,"showIconWarning":""},on:{"input":function($event){_vm.hasChanged.endDate = true}},model:{value:(_vm.form.endDate),callback:function ($$v) {_vm.$set(_vm.form, "endDate", $$v)},expression:"form.endDate"}})],1),_c('div',{staticClass:"section-time"},[_c('BannerDatePicker',{attrs:{"valueFormat":"time","formatted":"HH:mm:ss","fieldLabel":"Start Time","id":"banner-start-time","name":"bannerStartTime","label":"HH:MM:SS","required":true,"errorMessage":_vm.$v.form.startTime.$invalid && _vm.hasChanged.startTime
              ? 'field is required'
              : undefined,"showIconWarning":""},on:{"input":function($event){return _vm.isStartTimeMoreEndTime('startTime')}},model:{value:(_vm.form.startTime),callback:function ($$v) {_vm.$set(_vm.form, "startTime", $$v)},expression:"form.startTime"}}),_c('BannerDatePicker',{attrs:{"min-time":"","valueFormat":"time","formatted":"HH:mm:ss","fieldLabel":"End Time","id":"banner-end-time","name":"bannerEndTime","label":"HH:MM:SS","required":true,"errorMessage":_vm.$v.form.endTime.$invalid && _vm.hasChanged.endTime
              ? 'field is required'
              : undefined,"showIconWarning":""},on:{"input":function($event){return _vm.isStartTimeMoreEndTime('endTime')}},model:{value:(_vm.form.endTime),callback:function ($$v) {_vm.$set(_vm.form, "endTime", $$v)},expression:"form.endTime"}})],1)]),_c('div',{staticClass:"section-button"},[_c('Button',{staticClass:"text-sm px-16 py-3",attrs:{"customPadding":"","disabled":_vm.$v.form.$invalid,"buttonStyle":_vm.$v.form.$invalid ? 'gray' : 'normal'},on:{"click":function($event){_vm.confirmationModal = true}}},[_vm._v("Submit")])],1)]),_c('Modal',{attrs:{"visible":_vm.confirmationModal}},[_c('div',{attrs:{"slot":"modal-body"},slot:"modal-body"},[_c('div',{staticClass:"flex justify-center align-center"},[_c('ConfirmationIcon')],1),_c('h1',{staticClass:"leading-5 text-sm font-semibold text-bgMatterhorn m-2"},[_vm._v(" Want to Submit Pop Up Banner? ")]),_c('div',{staticClass:"text-xs text-secondaryText"},[_c('p',[_vm._v("Pop Up Banner will later be displayed in the application")])])]),_c('div',{attrs:{"slot":"modal-footer"},slot:"modal-footer"},[_c('Button',{staticClass:"text-xs mr-6",attrs:{"buttonStyle":"outline"},on:{"click":function($event){_vm.confirmationModal = false}}},[_vm._v("Check Again")]),_c('Button',{staticClass:"text-xs py-2 px-4 leading-5",on:{"click":_vm.onCreateBanner}},[_vm._v("Submit")])],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }